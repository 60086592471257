/*global define */

'use strict';

define('directives',['angular'], function(angular) {

/* Directives */

angular.module('myApp.directives', []).
  directive('appVersion', ['version', function(version) {
    return function(scope, elm, attrs) {
      elm.text(version);
    };
  }])
    .directive('roundConverter', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attrs, ngModelCtrl) {
                function roundNumber(val) {
                    var parsed = parseFloat(val, 10);
                    if(parsed !== parsed) { return null; } // check for NaN
                    var rounded = Math.round(parsed * 100) / 100;
                    return rounded;
                }
                // Parsers take the view value and convert it to a model value.
                ngModelCtrl.$parsers.push(roundNumber);
            }
        };
    })
    .directive('uiSelectRoute', function() {
        return {
            restrict: 'A',
            require: ['uiSelect', 'ngModel'],
            link: function(scope, element, attrs, ctrls) {
                var $select, ngModel;
                $select = ctrls[0];
                var ngModel = ctrls[1];
                return ngModel.$parsers.push(function(value) {
                    return {
                        userRouteId: value.userRouteId,
                        countryId: value.countryId
                    };
                });
            }
        };
    })
    .directive('mappingSelectField', function() {

        return {
            template: '<select data-ng-model="dropdowns" class="form-control"><option/><option value="mobile">Mobile</option><option value="customerid">Customer ID</option><option value="firstName">First Name</option><option value="lastName">Last Name</option><option value="birthday">Birth Date</option><option value="email">Email</option><option value="custom1">Custom 1</option><option value="custom2">Custom 2</option><option value="custom3">Custom 3</option><option value="custom4">Custom 4</option><option value="custom5">Custom 5</option></select>'
        }

    })
    .directive('insertMacro', ['$rootScope', function($rootScope) {
        return {
            link: function(scope, element, attrs) {
                $rootScope.$on('add', function(e, val) {
                    if(!angular.isDefined(val))
                        return;

                    val = '<' + val + '>'

                    var domElement = element[0];
                    if (document.selection) {
                        domElement.focus();
                        var sel = document.selection.createRange();
                        sel.text = val;
                        domElement.focus();
                    } else if (domElement.selectionStart || domElement.selectionStart === 0) {
                        var startPos = domElement.selectionStart;
                        var endPos = domElement.selectionEnd;
                        var scrollTop = domElement.scrollTop;
                        domElement.value = domElement.value.substring(0, startPos) + val + domElement.value.substring(endPos, domElement.value.length);
                        domElement.focus();
                        domElement.selectionStart = startPos + val.length;
                        domElement.selectionEnd = startPos + val.length;
                        domElement.scrollTop = scrollTop;
                    } else {
                        domElement.value += val;
                        domElement.focus();
                    }

                    //fire the event
                    if ("createEvent" in document) {  //NON IE browsers
                        var evt = document.createEvent("HTMLEvents");
                        evt.initEvent("change", false, true);
                        domElement.dispatchEvent(evt);
                    } else {  //IE
                        var evt = document.createEventObject();
                        domElement.fireEvent("onchange", evt);
                    }
                });
            }
        }
    }])

;
});
