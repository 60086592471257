/*global define */

'use strict';

define('controllers',[],function() {

/* Controllers */

var controllers = {};

controllers.MyCtrl1 = function() {};
controllers.MyCtrl1.$inject = [];

controllers.MyCtrl2 = function() {};
controllers.MyCtrl2.$inject = [];

controllers.OptimoveController =
    ['$scope', 'OptimoveService',
        function ($scope, optimove) {

            $scope.saveLoading = false;
            $scope.testLoading = false;
            $scope.eventsLoadings = false;
            $scope.trackEventsLoading = false;

            $scope.credentials;
            $scope.customerAttributes = [];
            $scope.status;

            getCredentials();

            function getCredentials() {
                optimove.getCredentials()
                    .success(function (creds) {
                        $scope.credentials = creds;
                    })
                    .error(function (error) {
                        $scope.status = 'Unable to load credential data: ' + error.message;
                    });
            }
            $scope.saveCredentials = function () {
                $scope.saveLoading = true;
                optimove.saveCredentials($scope.credentials)
                    .success(function (custs) {
                        $scope.status = 'Credentials Updated.';
                        $scope.saveLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Unable to update credential data: ' + error.message;
                        $scope.saveLoading = false;
                    });
            };

            $scope.getCustomerAttributes = function () {
                optimove.getCustomerAttributes()
                  .success(function (atts) {
                      $scope.customerAttributes = atts;
                  })
                  .error(function (error) {
                      $scope.customerAttributes = [];
                  });
            };


            $scope.testCredentials = function() {
                $scope.testLoading = true;
                optimove.testCredentials($scope.credentials)
                    .success(function (custs) {
                        $scope.status = custs.message;
                        $scope.testLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Test failed: ' + error.message;
                        $scope.testLoading = false;
                    });
            };

            $scope.registerEvents = function() {
                $scope.eventsLoading = true;
                optimove.registerEvents($scope.credentials)
                    .success(function (creds) {
                        $scope.credentials = creds;
                        $scope.eventsLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Error: ' + error.message;
                        $scope.eventsLoading = false;
                    });
            };

            $scope.unregisterEvents = function() {
                $scope.eventsLoading = true;
                optimove.unregisterEvents($scope.credentials)
                    .success(function (creds) {
                        $scope.credentials = creds;
                        $scope.eventsLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Error: ' + error.message;
                        $scope.eventsLoading = false;
                    });
            };

            $scope.registerTrackEvents = function() {
                $scope.trackEventsLoading = true;
                optimove.registerTrackEvents($scope.credentials)
                    .success(function (creds) {
                        $scope.credentials = creds;
                        $scope.trackEventsLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Error: ' + error.message;
                        $scope.trackEventsLoading = false;
                    });
            };

            $scope.unregisterTrackEvents = function() {
                $scope.trackEventsLoading = true;
                optimove.unregisterTrackEvents($scope.credentials)
                    .success(function (creds) {
                        $scope.credentials = creds;
                        $scope.trackEventsLoading = false;
                    })
                    .error(function (error) {
                        $scope.status = 'Error: ' + error.message;
                        $scope.trackEventsLoading = false;
                    });
            };

            $scope.selectProcessingType = function(ptype) {
                $scope.credentials.prefixProcessingType = ptype;
            }


        }];

controllers.LoginController =
        ['$scope', '$rootScope', 'AuthenticationService', '$location',
            function ($scope, $rootScope, AuthenticationService, $location) {
                $scope.login = function () {
                    $scope.dataLoading = true;
                    AuthenticationService.Login($scope.username, $scope.password, function(response) {
                        console.log('response: ', response);
                        if(response.success) {
                            console.log('Login Success: ', response);
                            AuthenticationService.SetCredentials($scope.username, response.authToken);
                            $location.path('/');
                        } else {

                            console.log('Login Error: ', response);
                            $scope.error = response.message;
                            $scope.dataLoading = false;
                        }
                    });
                };
                $scope.logout = function () {
                    $scope.dataLoading = true;
                    $location.path('/');
                    AuthenticationService.Logout(function(response) {

                        if(response.success) {
                            AuthenticationService.ClearCredentials();
                            $scope.dataLoading = false;
                        } else {
                            console.log('Logout Error: ', response);
                            AuthenticationService.ClearCredentials();
                            $scope.error = response.message;
                            $scope.dataLoading = false;
                        }
                    });
                };
            }];

controllers.WelcomeController = [ '$scope', '$rootScope', 'AuthenticationService', '$location', function($scope, $rootScope, AuthenticationService, $location) {
    var username = $location.search().username;
    var blenderToken = $location.search().blenderToken;

    AuthenticationService.CheckAuth(username, blenderToken, function(response) {
        console.log('response: ', response);
        if(response.code == 200) {
            console.log('Check Auth OK: ', response);
        } else {
            console.log('Check Auth ERR: ', response);
        }
    });
}];


controllers.ModalLoginController =
    ['$scope', '$modalInstance', 'AuthenticationService', '$route',
        function ($scope, $modalInstance, AuthenticationService, $route) {
            // reset login status
            AuthenticationService.ClearCredentials();

            $scope.login = function () {
                $scope.dataLoading = true;
                AuthenticationService.Login($scope.username, $scope.password, function(response) {
                    if(response.success) {
                        //console.log('Success: ', response);
                        AuthenticationService.SetCredentials($scope.username, response.authToken);
                        $modalInstance.close();
                        $route.reload();
                    } else {
                        //console.log('Error: ', response);
                        $scope.error = response.message;
                        $scope.dataLoading = false;
                    }
                });
            };
        }];

controllers.ContactsUploadController = ['$scope', 'Upload', function ($scope, Upload) {

    $scope.hideProgress = true;
    $scope.progressValue = 0;
    $scope.countTo = 100;
    $scope.countFrom = 0;
    $scope.completedFiles = [];


    $scope.$watch('files', function () {
        $scope.upload($scope.files);
    });

    $scope.upload = function (files) {
        if (files && files.length) {
            $scope.hideProgress = false;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                Upload.upload({
                    url: 'api/file/upload',
                    file: file
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.progressValue = progressPercentage;
                    console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
                }).success(function (data, status, headers, config) {
                    console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
                    $scope.completedFiles.push( data );
                    $scope.hideProgress = true;
                });
            }
        }
    };
}];

return controllers;

});




