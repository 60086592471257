/*global define */

'use strict';

define('history',[],function () {


    /* Controllers */

    var historyControllers = {};

    historyControllers.BatchListCtrl = ['$scope', 'Restangular', function ($scope, Restangular) {
        $scope.loading = false;
        $scope.dateFormat = "yyyy-MM-dd HH:mm";
        $scope.searchFormat = "yyyy-MM-dd'T'HH:mm:ss Z";
        $scope.openedS = false;
        $scope.openedE = false;
        $scope.detailsOpen = false;
        $scope.detailsId=null;

        var endDate = new Date();
        endDate.setHours(23,59,59,999);
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 2);
        startDate.setHours(0,0,0,0);

        $scope.getFormattedDate = function(d) {
            var month = d.getMonth() + 1;
            if(month<10) month = '0' + month;

            var date = d.getDate();
            if(date<10) date = '0' + date;

            var hours = d.getHours();
            if(hours<10) hours = '0' + hours;

            var minutes = d.getMinutes();
            if(minutes<10) minutes = '0' + minutes;

            var seconds = d.getSeconds();
            if(seconds<10) seconds = '0' + seconds;

            var offset = - d.getTimezoneOffset() / 60;
            if(offset>100) offset += '0';
            else offset += '00';
            if(offset>0) offset = '+' + offset;

            var str = d.getFullYear() + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds + ' ' + offset;
            return str;
        };

        $scope.searchParams = {
            offset: 0,
            limit: 10,
            startDate: startDate,
            endDate: endDate
        };

        $scope.openCalS = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedS = true;
            $scope.openedE = false;
        };

        $scope.openCalE = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedE = true;
            $scope.openedS = false;
        };

        $scope.setPage = function(page) {
            this.searchParams.offset = this.searchParams.limit * page;
            this.refresh();
        };

        $scope.nextPage = function() {
            this.searchParams.offset = this.searchParams.offset + this.searchParams.limit;
            this.refresh();
        };

        $scope.prevPage = function() {
            this.searchParams.offset = this.searchParams.offset - this.searchParams.limit;
            this.refresh();
        };

        $scope.details = function(id) {
            $scope.detailsOpen = true;
            $scope.detailsId = id;

        };

        $scope.openModal = function(id,action) {
                var id = 'modal-' + id;
                var modal = document.getElementById(id);
                if(action == 'show'){
                   modal.style.display = "block";
                }else if(action == 'close'){
                    modal.style.display = "none"
            }

        };

        $scope.hideDetails = function(){
            $scope.detailsOpen = false;
            $scope.detailsId = null;
        };

        $scope.refresh = function(offset) {
            if(!angular.isUndefined(offset))
                $scope.searchParams.offset = offset;

            $scope.loading = true;
            var endpoint = Restangular.all("api/xml/entity/message.LogicalMessage");

            var findParams = {
                startDate: this.getFormattedDate($scope.searchParams.startDate),
                endDate: this.getFormattedDate($scope.searchParams.endDate),
                offset: $scope.searchParams.offset,
                limit: $scope.searchParams.limit
            };

            endpoint.getList(findParams).then(function (response) {
                $scope.batches = response;
                $scope.loading = false;
            });
        };

        $scope.refresh();

    }];

    historyControllers.campaignListCtrl = ['$scope', 'Restangular', function ($scope, Restangular) {

    $scope.loading = false;
            $scope.dateFormat = "yyyy-MM-dd HH:mm";
            $scope.searchFormat = "yyyy-MM-dd'T'HH:mm:ss Z";
            $scope.openedS = false;
            $scope.openedE = false;

            var endDate = new Date();
            endDate.setHours(23,59,59,999);
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 2);
            startDate.setHours(0,0,0,0);

            $scope.getFormattedDate = function(d) {
                var month = d.getMonth() + 1;
                if(month<10) month = '0' + month;

                var date = d.getDate();
                if(date<10) date = '0' + date;

                var hours = d.getHours();
                if(hours<10) hours = '0' + hours;

                var minutes = d.getMinutes();
                if(minutes<10) minutes = '0' + minutes;

                var seconds = d.getSeconds();
                if(seconds<10) seconds = '0' + seconds;

                var offset = - d.getTimezoneOffset() / 60;
                if(offset>100) offset += '0';
                else offset += '00';
                if(offset>0) offset = '+' + offset;

                var str = d.getFullYear() + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds + ' ' + offset;
                return str;
            };

            $scope.searchParams = {
                offset: 0,
                limit: 10,
                startDate: startDate,
                endDate: endDate
            };

            $scope.openCalS = function($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.openedS = true;
                $scope.openedE = false;
            };

            $scope.openCalE = function($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.openedE = true;
                $scope.openedS = false;
            };

            $scope.setPage = function(page) {
                this.searchParams.offset = this.searchParams.limit * page;
                this.refresh();
            };

            $scope.nextPage = function() {
                this.searchParams.offset = this.searchParams.offset + this.searchParams.limit;
                this.refresh();
            };

            $scope.prevPage = function() {
                this.searchParams.offset = this.searchParams.offset - this.searchParams.limit;
                this.refresh();
            };

        /*$scope.refresh = function() {
            Restangular.all("api/entity/campaigns").getList().then(function (templates) {
                                $scope.templates = templates;
                            });
        };*/
            $scope.openModal = function(id,action) {
                        var id = 'modal-' + id;
                        var modal = document.getElementById(id);
                        if(action == 'show'){
                           modal.style.display = "block";
                        }else if(action == 'close'){
                            modal.style.display = "none"
                    }

                };

            $scope.getTotalCount = function(findParams){
                Restangular.all("api/entity/campaigns/count").getList(findParams).then(function (dataLength){
                                        $scope.total = dataLength[0];
                                        });
            };
            $scope.refresh = function(offset) {
                    if(!angular.isUndefined(offset))
                        $scope.searchParams.offset = offset;
                        /*$scope.isTotal = false;*/


                    $scope.loading = true;
                    var endpoint = Restangular.all("api/entity/campaigns");

                    var findParams = {
                        startDate: this.getFormattedDate($scope.searchParams.startDate),
                        endDate: this.getFormattedDate($scope.searchParams.endDate),
                        offset: $scope.searchParams.offset,
                        limit: $scope.searchParams.limit
                    };

                    /*if(!$scope.isTotal){
                        Restangular.all("api/entity/campaigns/count").getList(findParams).then(function (dataLength){
                        $scope.total = dataLength;
                        $scope.isTotal = true;
                        });
                    }*/
                    this.getTotalCount(findParams);

                    endpoint.getList(findParams).then(function (response) {
                        $scope.campaigns = response;
                        $scope.loading = false;
                    });
                };
        $scope.refresh();
        }];

    historyControllers.DeliveryListCtrl = ['$scope', 'Restangular', function ($scope, Restangular) {
        $scope.loading = false;
        $scope.dateFormat = "yyyy-MM-dd HH:mm";
        $scope.searchFormat = "yyyy-MM-dd'T'HH:mm:ss Z";
        $scope.openedS = false;
        $scope.openedE = false;

        var endDate = new Date();
        endDate.setHours(23,59,59,999);
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 2);
        startDate.setHours(0,0,0,0);


        $scope.getFormattedDate = function(d) {
            var month = d.getMonth() + 1;
            if(month<10) month = '0' + month;

            var date = d.getDate();
            if(date<10) date = '0' + date;

            var hours = d.getHours();
            if(hours<10) hours = '0' + hours;

            var minutes = d.getMinutes();
            if(minutes<10) minutes = '0' + minutes;

            var seconds = d.getSeconds();
            if(seconds<10) seconds = '0' + seconds;

            var offset = - d.getTimezoneOffset() / 60;
            if(offset>100) offset += '0';
            else offset += '00';
            if(offset>0) offset = '+' + offset;

            var str = d.getFullYear() + '-' + month + '-' + date + 'T' + hours + ':' + minutes + ':' + seconds + ' ' + offset;
            return str;
        };

        $scope.searchParams = {
            offset: 0,
            limit: 10,
            startDate: startDate,
            endDate: endDate
        };

        $scope.openCalS = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedS = true;
            $scope.openedE = false;
        };

        $scope.openCalE = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.openedE = true;
            $scope.openedS = false;
        };

        $scope.setPage = function(page) {
            this.searchParams.offset = this.searchParams.limit * page;
            this.refresh();
        };

        $scope.openModal = function(id,action) {
                        var id = 'modal-' + id;
                        var modal = document.getElementById(id);
                        if(action == 'show'){
                           modal.style.display = "block";
                        }else if(action == 'close'){
                            modal.style.display = "none"
                    }

                };

        $scope.nextPage = function() {
            this.searchParams.offset = this.searchParams.offset + this.searchParams.limit;
            this.refresh();
        };

        $scope.prevPage = function() {
            this.searchParams.offset = this.searchParams.offset - this.searchParams.limit;
            this.refresh();
        };

        $scope.refresh = function(offset) {
            if(!angular.isUndefined(offset))
                $scope.searchParams.offset = offset;

            $scope.loading = true;
            var endpoint = Restangular.all("api/xml/entity/message.DeliveryMessage");

            var findParams = {
                startDate: this.getFormattedDate($scope.searchParams.startDate),
                endDate: this.getFormattedDate($scope.searchParams.endDate),
                offset: $scope.searchParams.offset,
                limit: $scope.searchParams.limit
            };

            endpoint.getList(findParams).then(function (response) {
                $scope.deliveries = response;
                $scope.loading = false;
            });
        };

        $scope.refresh();

    }];
    return historyControllers;

});




