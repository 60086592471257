/*global define */

'use strict';

define('tokens',[],function () {

    /* Controllers */

    var tokenControllers = {};


    tokenControllers.ListCtrl = ['$scope', '$location', 'Restangular', function ($scope, $location, Restangular) {

        $scope.loading = false;

        $scope.deleteToken = function(id) {
            Restangular.one("api/blender/tokens/" + id).remove().then(function(p) {
                $scope.refresh();
            }, function(response) {
                console.log("Error with status code", response.status);
                if(response.status != 200)
                    $scope.status='Unable to delete token, is it in use?';
                else {
                    $scope.status = null;
                    $scope.refresh();
                }
            });

        };
        
        $scope.editToken = function(id) {
            $location.path('/auth/tokens/' + id);
        };

        $scope.refresh = function() {
            $scope.loading = true;
            Restangular.all("api/blender/tokens").getList().then(function (tokens) {
                $scope.tokens = tokens;
                $scope.loading = false;
            });
        }

        $scope.refresh();
    }];


    tokenControllers.CreateCtrl = ['$scope', '$http', '$location', function ($scope, $http, $location) {

        $scope.username = '';
        $scope.password = '';

        $scope.addToken = function() {
            $http.post('/api/blender/tokens', {username: $scope.username, password: $scope.password})
            .success(function (response) {
                if(response.code != 200)
                    $scope.status = response.message;
                else
                    $location.path('/auth/tokens');
            }).error(function (response) {
                $scope.status = response.message;
            })
        }
        ;
    }];

    return tokenControllers;

});




