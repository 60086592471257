/*global define */

'use strict';

define('filters',['angular'], function(angular) {

/* Filters */

angular.module('myApp.filters', []).
  filter('interpolate', ['version', function(version) {
    return function(text) {
      return String(text).replace(/\%VERSION\%/mg, version);
    }
  }])
    .filter('round3', ['version', function(version) {
        return function(val) {
            var parsed = parseFloat(val, 10);
            if(parsed !== parsed) { return null; } // check for NaN
            var rounded = (Math.round(parsed * 100) / 100).toFixed(3);
            return rounded;
        }
    }])
    .filter('ceil', [function() {
        return function(val) {
            var parsed = parseFloat(val, 10);
            if(parsed !== parsed) { return 0; } // check for NaN
            var rounded = Math.ceil(parsed);
            return rounded;
        }
    }])
    .filter('parsedate', ['moment', function(moment) {
        return function(val) {
            var m = moment(val);
            return m.toDate();
        }
    }])
    .filter('isnum', function() {
        return function(input) {
            var inp = parseInt(input, 10);
            if(isNaN(inp))
                return false;

            if(inp != input)
                return false;

            return true;
        }
    })

    .filter('percentage', ['$filter', function ($filter) {
        return function (input, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        };
    }]);
;

});
