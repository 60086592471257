/*global define */

'use strict';

define('optouts',[],function () {

    /* Controllers */

    var optoutControllers = {};

    optoutControllers.ImportOptoutsCtl = ['$scope', '$location', 'Restangular', 'Gsm0338Service', function ($scope, $location, Restangular, Gsm0338Service) {

        $scope.dataCount = 0;
        $scope.optoutCount = 0;
        $scope.optouts = '';
        $scope.prefix = '';
        $scope.data = {};
        $scope.templates = [];
        $scope.saving = false;
        $scope.confirming = false;


        $scope.$watch('optouts', function() {

            if ($scope.optouts === undefined) {
                $scope.data.optouts = [];
                $scope.optoutCount = 0;
                return;
            }

            $scope.optouts = $scope.optouts.replace(/[^0-9\n]/g,'');

            $scope.optouts = $scope.optouts.replace(/^\s*[\r\n]/gm,'');
            $scope.data.optouts = $scope.optouts.split('\n');
            $scope.optoutCount = $scope.data.optouts.length;
        });


        $scope.importOptouts = function () {
            $scope.confirming = false;
            $scope.saving = true;
            var sendData = {
                prefix: $scope.prefix,
                optouts: $scope.data.optouts
            }
            Restangular.all('api/optouts/import').post(sendData).then(function (response) {
                $scope.saving = false;
                $scope.status = response.message;
                console.log('response', response);
            }, function (response) {
                $scope.status = response.data.data;
                $scope.saving = false;
            });
        };

        $scope.confirmOpen = function(){
            $scope.confirming = true;
        };

        $scope.confirmClose = function(){
            $scope.confirming = false;
        };

        $scope.clear = function(){
            $scope.prefix = '';
            $scope.optouts = '';
        };

        $scope.filterValue = function($event){
            if(isNaN(String.fromCharCode($event.keyCode))){
                $event.preventDefault();
            }
        };

    }];


    return optoutControllers;

});




