/*global require, requirejs */

'use strict';

requirejs.config({

    paths: {
        'angular': '../lib/angularjs/angular',
        'angular-route': '../lib/angularjs/angular-route',
        'ngSanitize': '../lib/angularjs/angular-sanitize',
        'ngAnimate': '../lib/angularjs/angular-animate',
        'ui-bootstrap-tpls': '../lib/angular-ui-bootstrap/ui-bootstrap-tpls',
        'lodash': '../lib/lodash/lodash',
        'restangular': '../lib/restangular/restangular',
        'ngFileUploadShim': '../lib/angular-file-upload/ng-file-upload-shim',
        'ngFileUpload': '../lib/angular-file-upload/ng-file-upload',
        'x2js': '../lib/x2js/xml2json',
        'ui.select': '../lib/angular-ui-select/select',
        'moment': '../lib/momentjs/moment',
        'moment-angular': '../lib/angular-moment/angular-moment'
    },
    shim: {
        'angular': {
            exports: 'angular'
        },
        'angular-route': {
            deps: ['angular'],
            exports: 'angular'
        },
        'ngSanitize': {
            deps: ['angular'],
            exports: 'angular'
        },
        'ui-bootstrap-tpls': {
            deps: ['angular']
        },
        'lodash': {},
        'restangular': {
            deps: ['angular', 'lodash']
        },
        'ngFileUpload': {
            deps: ['angular', 'ngFileUploadShim']
        },
        'ui.select': {
            deps: ['angular']
        },
        'ngAnimate': {
            deps: ['angular']
        },
        'moment-angular': {
            deps: ['angular', 'moment']
        }
    }
});

require(['angular', './controllers', './templates', './tokens', './alternatelogins', './history', './contacts', './sendmessage', './optouts', './directives', './filters', './services', 'angular-route', 'ui-bootstrap-tpls', 'restangular', 'ngFileUpload', 'x2js', 'ui.select', 'ngSanitize', 'ngAnimate', 'moment-angular'],
    function (angular, controllers, templateControllers, tokenControllers, altloginControllers, historyControllers, contactControllers, sendControllers, optoutControllers) {

        // Declare app level module which depends on filters, and services

        var myModule = angular.module('opti', ['ui.bootstrap', 'myApp.filters', 'myApp.services', 'myApp.directives', 'ngRoute', 'ui.bootstrap', 'restangular', 'ngFileUpload', 'ui.select', 'ngSanitize', 'ngAnimate', 'angularMoment'])
            .config(['$routeProvider', function ($routeProvider) {
                $routeProvider.when('/auth/check', {
                    templateUrl: 'partials/auth/check.html',
                    controller: controllers.WelcomeController
                });
                $routeProvider.when('/', {
                    templateUrl: 'partials/auth/welcome.html',
                    controller: controllers.WelcomeController
                });

                /************* AUTH *************/

                $routeProvider.when('/auth/login', {
                    templateUrl: 'partials/auth/login.html',
                    controller: controllers.LoginController
                });
                $routeProvider.when('/auth/logout', {
                    templateUrl: 'partials/auth/logout.html',
                    controller: controllers.LoginController
                });

                /************* OPTIMOVE Credentials *************/

                $routeProvider.when('/optimove/credentials', {
                    templateUrl: 'partials/optimove/credentials.html',
                    controller: controllers.OptimoveController
                });

                /************* FIELD MAPPING *************/

                $routeProvider.when('/optimove/templates/fieldmapping', {
                    templateUrl: 'partials/optimove/fieldmapping.html',
                    controller: templateControllers.MappingCtrl
                });

                /************* OPTIMOVE Templates *************/

                $routeProvider.when('/optimove/templates', {
                    templateUrl: 'partials/optimove/templates.html',
                    controller: templateControllers.ListCtrl
                });
                $routeProvider.when('/optimove/templates/new', {
                    templateUrl: 'partials/optimove/template_detail.html',
                    controller: templateControllers.CreateCtrl
                });
                $routeProvider.when('/optimove/templates/:id', {
                    templateUrl: 'partials/optimove/template_detail.html',
                    controller: templateControllers.EditCtrl,
                    resolve: {
                        template: ['Restangular', '$route', function (Restangular, $route) {
                            return Restangular.one('api/optimove/templates', $route.current.params.id).get();
                        }]
                    }
                });

                $routeProvider.when('/optimove/templates/:id/languages', {
                    templateUrl: 'partials/optimove/template_languages.html',
                    controller: templateControllers.LanguageCtrl,
                    resolve: {
                        template: ['Restangular', '$route', function (Restangular, $route) {
                            return Restangular.one('api/optimove/templates', $route.current.params.id).get();
                        }]
                    }
                });


                /************* BLENDER TOKENS *************/

                $routeProvider.when('/auth/tokens', {
                    templateUrl: 'partials/auth/tokens.html',
                    controller: tokenControllers.ListCtrl
                });
                $routeProvider.when('/auth/tokens/new', {
                    templateUrl: 'partials/auth/token_set.html',
                    controller: tokenControllers.CreateCtrl
                });

                /************* ALTERNATE LOGINS *************/

                $routeProvider.when('/auth/altlogin', {
                    templateUrl: 'partials/auth/alternate_login.html',
                    controller: altloginControllers.ListCtrl
                });
                $routeProvider.when('/auth/altlogin/new', {
                    templateUrl: 'partials/auth/alternate_login_set.html',
                    controller: altloginControllers.CreateCtrl
                });

                /************* CONTACTS *************/

                $routeProvider.when('/optimove/contacts', {
                    templateUrl: 'partials/contacts/contactlist.html',
                    controller: contactControllers.ListCtrl
                });

                $routeProvider.when('/optimove/contacts/upload', {
                    templateUrl: 'partials/contacts/upload.html',
                    controller: contactControllers.UploadCtrl
                });

                $routeProvider.when('/optimove/contacts/map/:fileId', {
                    templateUrl: 'partials/contacts/map.html',
                    controller: contactControllers.MappingCtrl
                });



                /************* HISTORY *************/

                $routeProvider.when('/history/batches', {
                    templateUrl: 'partials/history/batches.html',
                    controller: historyControllers.BatchListCtrl
                });

                $routeProvider.when('/history/deliveries', {
                                    templateUrl: 'partials/history/deliveries.html',
                                    controller: historyControllers.DeliveryListCtrl
                                });

                $routeProvider.when('/history/campaignList', {
                                     templateUrl: 'partials/history/campaigns.html',
                                     controller: historyControllers.campaignListCtrl
                                });

                /************* Send to customer *************/

                $routeProvider.when('/send/customers', {
                    templateUrl: 'partials/optimove/send_message.html',
                    controller: sendControllers.SendMessageCtrl
                });

                /************* Optouts *************/

                $routeProvider.when('/manage/optouts', {
                    templateUrl: 'partials/contacts/optout.html',
                    controller: optoutControllers.ImportOptoutsCtl
                });

                $routeProvider.otherwise({redirectTo: '/'});


            }]
        ).config(['$httpProvider', function ($httpProvider) {
                //initialize get if not there
                if (!$httpProvider.defaults.headers.get) {
                    $httpProvider.defaults.headers.get = {};
                }
                //disable IE ajax request caching
                $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                // extra
                $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
                $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

                $httpProvider.interceptors.push(['$rootScope', '$q', '$injector', function ($rootScope, $q, $injector) {
                    return {
                        // optional method
                        'responseError': function (rejection) {
                            var $modal = $injector.get('$modal');

                            console.log('responseError!!', rejection);
                            if (rejection.status == 401) {
                                var modalInstance = $modal.open({
                                    animation: true,
                                    templateUrl: '/partials/auth/loginmodal.html',
                                    controller: controllers.ModalLoginController,
                                    backdrop: 'static',
                                    keyboard: false,
                                    size: 'md'
                                });

                                console.log('401 error ehre');
                            }

                            return $q.reject(rejection);
                        }
                    };
                }]);
            }]).config(['RestangularProvider', function (RestangularProvider) {

                RestangularProvider.addResponseInterceptor(function (data, operation, route, url, response, deferred) {
                    // Check if it's a xml api call, otherwise return data
                    var regex = /\/api\/xml\/entity\/\w+\.(\w+).*/gi;
                    var match = regex.exec(url);
                    if (match == null) {
                        if (operation === 'getList' && angular.isDefined(data.list)) {
                            var list = data.list;
                            list['paging'] = {
                                total: data.total,
                                offset: data.offset
                            };
                            return list;
                        }
                        return data;
                    }

                    // xmlapi -> convert to json and extract entities.
                    var entity = match[1].toLowerCase();

                    var x2js = new X2JS();
                    var json = x2js.xml_str2json(data);

                    var list = [];

                    if (angular.isDefined(json.xaresponse.entitylist)){

                    if (angular.isDefined(json.xaresponse.entitylist[entity])) {

                        if (angular.isArray(json.xaresponse.entitylist[entity])) {
                            list = json.xaresponse.entitylist[entity];
                        } else {
                            list.push(json.xaresponse.entitylist[entity]);
                        }
                    }

                    list['paging'] = {
                        total: json.xaresponse.entitylist._total,
                        offset: json.xaresponse.entitylist._offset
                    };
                    }else{
                        list.push(json.xaresponse.entity);
                    }
                    return list;

                });
            }]);

        /*
         // Session injector
         myModule.factory('sessionInjector', ['SessionService', function(SessionService) {
         var sessionInjector = {
         request: function(config) {
         if (!SessionService.isAnonymus) {
         config.headers['x-session-token'] = SessionService.token;
         }
         return config;
         }
         };
         return sessionInjector;
         }]);
         myModule.config(['$httpProvider', function($httpProvider) {
         $httpProvider.interceptors.push('sessionInjector');
         }]);
         */
        myModule.run(['AuthenticationService', function(AuthenticationService) {
            AuthenticationService.LoadCredentials();
        }]);
        angular.bootstrap(document, ['opti']);

    });

define("main", function(){});

