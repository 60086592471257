/*global define */

'use strict';

define('templates',[],function () {

    /* Utils */

    function willUrlBeShortened(originalUrl, shortUrl) {
        originalUrl = originalUrl.replace("http://", "");
        originalUrl = originalUrl.replace("https://", "");


        return shortUrl.length < originalUrl.length;
    }

    /* Controllers */

    var dummyUrl = 'www.d.com/';
    var shortUrlPathSample = '/abcdefg';

    var templateControllers = {};

    var templateMacros = [
        {name: "First Name", value: "<c.firstName>"},
        {name: "Last Name", value: "<c.lastName>"},
        {name: "Email", value: "<c.email>"},
        {name: "Mobile", value: "<c.mobile>"},
        {name: "Custom 1", value: "<c.custom1>"},
        {name: "Custom 2", value: "<c.custom2>"},
        {name: "Custom 3", value: "<c.custom3>"},
        {name: "Custom 4", value: "<c.custom4>"},
        {name: "Custom 5", value: "<c.custom5>"}
    ];

    // TODO: Loading this data from backend
    var templateChannels = [
        {name: "Campaign", value: "441"},
        {name: "Track & Trigger", value: "202"},
    ];

    var loremIpsum = "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur";

    templateControllers.MappingCtrl = ['$scope', '$location', 'Restangular', function ($scope, $location, Restangular) {
        $scope.fieldMapping = {};
        $scope.saving = false;

        Restangular.one("api/optimove/templates/fieldmapping").get().then(function (lines) {
            $scope.fieldMapping = lines;
        });

        $scope.save = function () {
            $scope.saving = true;
            Restangular.all('api/optimove/templates/fieldmapping').post($scope.fieldMapping).then(function (fieldMapping) {
                $scope.saving = false;
                $scope.status = 'Mapping saved successfully';
                $location.path('/optimove/templates');
            }, function (response) {
                $scope.status = response.data.message;
                $scope.saving = false;
            });
        };


    }];

    templateControllers.ListCtrl = ['$scope', '$location', 'Restangular', '$modal', 'StorageService', function ($scope, $location, Restangular, $modal, StorageService) {
        $scope.selectedGroup = {name: "All Templates", id: -1};
        $scope.templateGroups = [
            {name: "All Templates", id: null}
        ];

        $scope.templates = [];
        $scope.loading = false;
        $scope.searchParams = {
            offset: 0,
            limit: 10,
            search: '',
            group: null
        };

        var searchParams = StorageService.get("template_search_params");
        if (searchParams != null)
            $scope.searchParams = searchParams;

        $scope.editTemplate = function (id) {
            $location.path('/optimove/templates/' + id);
        };

        $scope.setPage = function (page) {
            $scope.searchParams.offset = $scope.searchParams.limit * page;
            $scope.refresh();
        };

        $scope.nextPage = function () {
            $scope.searchParams.offset = $scope.searchParams.offset + $scope.searchParams.limit;
            $scope.refresh();
        };

        $scope.prevPage = function () {
            $scope.searchParams.offset = $scope.searchParams.offset - $scope.searchParams.limit;
            $scope.refresh();
        };

        $scope.search = function () {
            $scope.searchParams.offset = 0;
            $scope.refresh();
        };

        $scope.clearSearch = function () {
            $scope.searchParams.offset = 0;
            $scope.searchParams.search = '';
            $scope.refresh();
        };

        $scope.refresh = function (offset) {
            if (!angular.isUndefined(offset))
                $scope.searchParams.offset = offset;

            $scope.loading = true;
            var endpoint = Restangular.all("api/optimove/templates");

            endpoint.getList($scope.searchParams).then(function (response) {
                $scope.templates = response;
                var left = $scope.searchParams.limit - $scope.templates.length;
                for (var i = 0; i < left; i++) {
                    $scope.templates.push({id: -1, name: 'zzzz999', text: ''});
                }

                StorageService.set("template_search_params", $scope.searchParams, false);

                $scope.loading = false;
            });
        };


        $scope.refresh();
        /*
        Restangular.all("api/optimove/templates").getList().then(function (templates) {
            console.log("Templates", templates);
            $scope.templates = templates;
        });
        */

        /*** GROUPS **/
        $scope.selectGroup = function (group) {
            $scope.selectedGroup = group;
            $scope.searchParams.group = group.id;
            $scope.refresh(0);
        };

        var baseGroups = Restangular.all('api/templategroups');

        baseGroups.getList().then(function (lines) {
            $scope.templateGroups = lines;
        });

        $scope.createGroup = function () {
            // Show modal
            var parentElem = angular.element(document.getElementById('templateList'));
            var $ctrl = this;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'createTemplateGroup.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                resolve: {
                    formData: function () {
                        return {
                            //fields: getFormFields(),
                            //model: {}
                        }
                    }
                },
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                    $scope.listName = "New Group Name";

                    $scope.saveGroup = function () {
                        $modalInstance.close($scope.listName);
                    }
                    $scope.cancel = function () {
                        $modalInstance.dismiss("Reason");
                    }
                }]
            });

            modalInstance.result.then(function (listName) {
                var newList = {name: listName};
                baseGroups.post(newList).then(function (result) {
                    $scope.selectGroup(result);
                }, function (response) {
                    $scope.status = response.data.message;
                    $scope.saving = false;
                });
            }, function (reason) {
                //console.log('Modal dismissed: ' + reason);
            });

        };
    }];


    templateControllers.CreateCtrl = ['$scope', '$rootScope', '$location', 'Restangular', 'OptimoveService', 'Gsm0338Service', function ($scope, $rootScope, $location, Restangular, OptimoveService, Gsm0338Service) {

        $scope.template = {};
        $scope.selectedMacro;
        $scope.templateMacros = templateMacros;
        $scope.templateMacrosReplacements = {};
        $scope.templateMacroReplacementRE = null;
        $scope.dummyText = '';

        $scope.templateChannels = templateChannels;
        $scope.isUnicode = false;
        $scope.messageCount = 1;
        $scope.blenderTokens = [];

        $scope.shortUrlMessageLength = 0;
        $scope.unshortUrlMessageLength = 0;
        $scope.shortUrlExample = '';
        $scope.urlExp = "\\s\\b((?:[a-z][\\w-]+:(?:\\/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\\/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’]))"
        $scope.urlRegex = new RegExp($scope.urlExp, 'g');


        Restangular.all("api/blender/tokens").getList().then(function (tokens) {
            $scope.blenderTokens = tokens;
            $scope.template.tokenId = tokens[0].id;
        });

        Restangular.all("api/optimove/channelids").getList().then(function (channelids) {
            $scope.templateChannels = channelids;
        });

        Restangular.one("api/property/SHORTURL_DEFAULT").get().then(function (propValue) {
            // $scope.fieldMapping = lines;
            // console.log('SHORTURL_DEFAULT', propValue.message);
            $scope.template.shorturl = propValue.message == 'true' ? true : false;

        });

        OptimoveService.getShortUrlDomain(function(shortUrl) { $scope.shortUrlDomain = shortUrl; });

        // TODO Load this data from backend
        $scope.template.channelId = '441';

        Restangular.all("api/optimove/attributes").getList().then(function (attributes) {
            if (attributes.length == 0)
                return;

            $scope.templateMacros = attributes;

            // Create replacement array to help calculate message length
            for(var i=0;i<attributes.length;i++) {
                var searchKey = '<' + attributes[i].name + '>'
                var replaceValue = loremIpsum.substring(0, attributes[i].length);
                if(attributes[i].description.includes('URL')) {
                    var additionalChars = (attributes[i].length - dummyUrl.length + 1);
                    if(additionalChars < 0)
                        replaceValue = dummyUrl;
                    else
                        replaceValue = dummyUrl + loremIpsum.substring(0, additionalChars).replaceAll(' ', '_');
                }

                $scope.templateMacrosReplacements[searchKey] = replaceValue;
            }
            $scope.templateMacroReplacementRE = new RegExp(Object.keys($scope.templateMacrosReplacements).join("|"), "gi");

        });

        $scope.save = function () {
            // Check if user is sure they don't want to shorten the url
            // Check if user is sure they don't want to shorten the url
            var urlFound = $scope.urlRegex.exec($scope.template.text);
            if($scope.template.shorturl == false && urlFound != null) {
                var r = confirm("URL Detected in message but Short URL not enabled. Save anyway?");
                if(r == false)
                    return;
            }

            $scope.saving = true;
            Restangular.all('api/optimove/templates').post($scope.template).then(function (template) {
                $location.path('/optimove/templates');
            }, function (response) {
                $scope.status = response.data.message;
                $scope.saving = false;
            });
        };

        $scope.test = function () {
            $scope.testing = true;
            Restangular.all('api/optimove/templates/test').post($scope.template).then(function (response) {
                $scope.testresult = response.message;
                $scope.status = null;
                $scope.testing = false;
            }, function (response) {
                $scope.testresult = null;
                $scope.status = response.data.message;
                $scope.testing = false;
            });
        };

        $scope.watchTemplateText = function () {
            if (angular.isDefined($scope.template)) {
                if ($scope.template.text == null)
                    return;

                // Now do the same with replacement texts
                $scope.dummyText = $scope.template.text.replace($scope.templateMacroReplacementRE, function(matched) {
                    return $scope.templateMacrosReplacements[matched];
                });

                $scope.isUnicode = Gsm0338Service.isUnicode($scope.template.text);
                $scope.unshortUrlMessageLength = $scope.dummyText.length

                var found = $scope.urlRegex.exec($scope.dummyText);
                if (found != null) {
                    var replaced = $scope.dummyText.replace($scope.urlRegex, function (str) {
                        const newUrl = $scope.shortUrlDomain + shortUrlPathSample;
                        if(willUrlBeShortened(str, newUrl))
                            return newUrl;
                        else
                            return str;
                    });

                    $scope.shortUrlExample = 'E.g. ' + found[0] + ' -> ' + $scope.shortUrlDomain + shortUrlPathSample;
                    $scope.shortUrlMessageLength = replaced.length;
                    if($scope.template.shorturl)
                        $scope.dummyText = replaced;
                } else {
                    $scope.shortUrlExample = 'URL not found in message';
                    $scope.shortUrlMessageLength = $scope.dummyText.length;
                }

                $scope.messageCount = Gsm0338Service.getSegmentCount($scope.dummyText);
            }
        };

        $scope.$watch('template.text', $scope.watchTemplateText);

        $scope.$watch('template.shorturl', $scope.watchTemplateText);

        $scope.$watch('selectedMacro', function () {
            if ($scope.selectedMacro === undefined)
                return;
            $rootScope.$broadcast('add', $scope.selectedMacro);
            $scope.selectedMacro = undefined;
        });

        $scope.$watch('template.tokenId', function () {
            if ($scope.template.tokenId === undefined)
                return;

            OptimoveService.getShortUrlDomain(
                function(shortUrl) {
                    $scope.shortUrlDomain = shortUrl;
                    $scope.watchTemplateText();
                },
                $scope.template.tokenId
            );
        });


        /** Template Groups **/
        var baseGroups = Restangular.all('api/templategroups');

        baseGroups.getList().then(function (lines) {
            $scope.templateGroups = lines;
        });
        /** Template Groups **/

        $scope.toggleTemplatePreview = function() {
            var x = document.getElementById("template_preview");
            if (x.style.display === "none" || x.style.display === "") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        };
    }];

    templateControllers.EditCtrl = ['$scope', '$rootScope', '$location', '$modal', '$compile', '$templateCache', 'Restangular', 'template', 'OptimoveService', 'Gsm0338Service', 'ASCIIFolder', function ($scope, $rootScope, $location, $modal, $compile, $templateCache, Restangular, template, OptimoveService, Gsm0338Service, ASCIIFolder) {
        $scope.templateGroups = [
            {name: "All Templates", id: null}
        ];
        $scope.selectedMacro;
        $scope.templateMacros = templateMacros;
        $scope.templateMacrosReplacements = {};
        $scope.templateMacroReplacementRE = null;
        $scope.dummyText = '';

        $scope.templateChannels = templateChannels;

        var original = template;
        $scope.template = Restangular.copy(original);
        $scope.isUnicode = false;
        $scope.messageCount = 1;
        $scope.blenderTokens = [];

        $scope.shortUrlMessageLength = 0;
        $scope.unshortUrlMessageLength = 0;
        $scope.shortUrlExample = '';
        $scope.urlExp = "\\b((?:[a-z][\\w-]+:(?:\\/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\\/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’]))"
        $scope.urlRegex = new RegExp($scope.urlExp, 'g');

        OptimoveService.getShortUrlDomain(
            function(shortUrl) {
                $scope.shortUrlDomain = shortUrl;
                $scope.watchTemplateText();
            },
            $scope.template.tokenId
        );

        Restangular.all("api/optimove/channelids").getList().then(function (channelids) {
            $scope.templateChannels = channelids;
        });

        Restangular.all("api/blender/tokens").getList().then(function (tokens) {
            $scope.blenderTokens = tokens;
        });


        Restangular.all("api/optimove/attributes").getList().then(function (attributes) {

            if (attributes.length == 0)
                return;

            $scope.templateMacros = attributes;

            // Create replacement array to help calculate message length
            for(var i=0;i<attributes.length;i++) {
                var searchKey = '<' + attributes[i].name + '>'
                var replaceValue = loremIpsum.substring(0, attributes[i].length);
                if(attributes[i].description.includes('URL')) {
                    var additionalChars = (attributes[i].length - dummyUrl.length + 1);
                    if(additionalChars < 0)
                        replaceValue = dummyUrl;
                    else
                        replaceValue = dummyUrl + loremIpsum.substring(0, additionalChars).replaceAll(' ', '_');
                }

                $scope.templateMacrosReplacements[searchKey] = replaceValue;
            }
            $scope.templateMacroReplacementRE = new RegExp(Object.keys($scope.templateMacrosReplacements).join("|"), "gi");
            $scope.watchTemplateText();
        });


        $scope.isClean = function () {
            return angular.equals(original, $scope.template);
        };

        $scope.destroy = function () {
            $scope.deleting = true;
            original.remove().then(function () {
                $location.path('/optimove/templates');
            }, function (response) {
                $scope.status = response.data.message;
                $scope.deleting = false;
            });
        };

        $scope.watchTemplateText = function () {
            if ($scope.template.text == null)
                return;

            // Now do the same with replacement texts
            $scope.dummyText = $scope.template.text.replace($scope.templateMacroReplacementRE, function(matched) {
                return $scope.templateMacrosReplacements[matched];
            });

            $scope.isUnicode = Gsm0338Service.isUnicode($scope.template.text);
            $scope.unshortUrlMessageLength = $scope.dummyText.length

            var found = $scope.urlRegex.exec($scope.dummyText);
            if (found != null) {
                var replaced = $scope.dummyText.replace($scope.urlRegex, function (str) {
                    const newUrl = $scope.shortUrlDomain + shortUrlPathSample;
                    if(willUrlBeShortened(str, newUrl))
                        return newUrl;
                    else
                        return str;
                });

                $scope.shortUrlExample = 'E.g. ' + found[0] + ' -> ' + $scope.shortUrlDomain + shortUrlPathSample;
                $scope.shortUrlMessageLength = replaced.length;

                if($scope.template.shorturl)
                    $scope.dummyText = replaced;

            } else {
                $scope.shortUrlExample = 'URL not found in message';
                $scope.shortUrlMessageLength = $scope.dummyText.length;
            }

            $scope.messageCount = Gsm0338Service.getSegmentCount($scope.dummyText);
        };

        $scope.$watch('template.text', $scope.watchTemplateText);

        $scope.$watch('template.shorturl', $scope.watchTemplateText);

        $scope.$watch('template.tokenId', function () {
            if ($scope.template.tokenId === undefined)
                return;

            OptimoveService.getShortUrlDomain(
        function(shortUrl) {
                    $scope.shortUrlDomain = shortUrl;
                    $scope.watchTemplateText();
                },
                $scope.template.tokenId
            );
        });

        $scope.save = function () {

            // Check if user is sure they don't want to shorten the url
            var urlFound = $scope.urlRegex.exec($scope.template.text);
            if($scope.template.shorturl == false && urlFound != null) {
                var r = confirm("URL Detected in message but Short URL not enabled. Save anyway?");
                if(r == false)
                    return;
            }

            $scope.saving = true;
            $scope.template.put().then(function () {
                $location.path('/optimove/templates');
            }, function (response) {
                $scope.status = response.data.message;
                $scope.saving = false;
            });
        };

        $scope.duplicate = function () {
            $scope.saving = true;
            $scope.template.name = 'Clone: ' + $scope.template.name;
            Restangular.all('api/optimove/templates/clone').post($scope.template).then(function (template) {
                $location.path('/optimove/templates');
            }, function (response) {
                $scope.status = response.data.message;
                $scope.saving = false;
            });
        };

        $scope.test = function () {
            $scope.testing = true;
            Restangular.all('api/optimove/templates/test').post($scope.template).then(function (response) {
                $scope.testresult = response.message;
                $scope.status = null;
                $scope.testing = false;
            }, function (response) {
                $scope.testresult = null;
                $scope.status = response.data.message;
                $scope.testing = false;
            });
        };

        $scope.testSend = function (listId) {
            $scope.testing = true;
            var templateSendTest = {
                templateId: $scope.template.id,
                listId: listId
            }
            Restangular.all('api/optimove/templates/send').post(templateSendTest).then(function (response) {
                $scope.testresult = response.message;
                $scope.status = null;
                $scope.testing = false;
            }, function (response) {
                $scope.testresult = null;
                $scope.status = response.data.message;
                $scope.testing = false;
            });
        };

        $scope.testSendSelectListModal = function () {
            // Show modal
            var parentElem = angular.element(document.getElementById('templateEditPanelBody'));
            var $ctrl = this;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'testSendTemplate.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                controller: ['$scope', '$modalInstance', 'Restangular', function ($scope, $modalInstance, Restangular) {

                    /** Contact Lists **/
                    $scope.selectedList = {name: "Please select list", id: -1};
                    $scope.contactLists = [];

                    var baseLists = Restangular.all('api/contactlists');

                    baseLists.getList().then(function (lines) {
                        $scope.contactLists = lines;
                        angular.forEach($scope.contactLists, function (item) {
                            if (item.live) {
                                var index = $scope.contactLists.indexOf(item);
                                $scope.contactLists.splice(index, 1);
                            }
                        });

                    });

                    $scope.selectList = function (list) {
                        $scope.selectedList = list;
                    };

                    /** Contact Lists **/

                    $scope.listName = "New List Name";

                    $scope.runTest = function () {
                        $modalInstance.close($scope.selectedList);
                    }
                    $scope.cancel = function () {
                        $modalInstance.dismiss("Canceled");
                    }
                }]
            });

            modalInstance.result.then(function (list) {
                $scope.testSend(list.id);
            }, function (reason) {
                //console.log('Modal dismissed: ' + reason);
            });

        };


        $scope.$watch('selectedMacro', function () {
            if ($scope.selectedMacro === undefined)
                return;
            $rootScope.$broadcast('add', $scope.selectedMacro);
            $scope.selectedMacro = undefined;
        });

        /** Template Groups **/
        var baseGroups = Restangular.all('api/templategroups');

        baseGroups.getList().then(function (lines) {
            $scope.templateGroups = lines;
        });
        /** Template Groups **/

        $scope.showGsm0338Highlights = function () {
            var highlighted = Gsm0338Service.highlightNonGsm0338($scope.template.text);
            // Show modal
            var parentElem = angular.element(document.getElementById('templateEditPanelBody'));
            var $ctrl = this;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'template_gsm_highlight.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {

                    $scope.highlighted = highlighted;

                    $scope.close = function () {
                        $modalInstance.dismiss("Close");
                    }
                }]
            });

        };

        $scope.toggleTemplatePreview = function() {
            var x = document.getElementById("template_preview");
            console.log('template_preview: ', x.style.display)
            if (x.style.display === "none" || x.style.display === "") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        };

        $scope.showTemplateDateData = function () {
            // Show modal
            var parentElem = angular.element(document.getElementById('templateEditPanelBody'));
            var $ctrl = this;
            var temp = $scope.template;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'template_date_data.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {

                    $scope.template = $ctrl.template;
                    // $scope.template = template;

                    $scope.close = function () {
                        $modalInstance.dismiss("Close");
                    }
                }]
            });

        };


    }];


    templateControllers.LanguageCtrl = ['$scope', '$rootScope', '$location', '$modal', 'Restangular', 'template', 'RoutesService', 'Gsm0338Service', 'OptimoveService', function ($scope, $rootScope, $location, $modal, Restangular, template, RoutesService, Gsm0338Service, OptimoveService) {
        $scope.templateLanguages = [];
        $scope.selectedMacro;
        $scope.templateMacros = templateMacros;

        var original = template;
        $scope.template = Restangular.copy(original);


        $scope.templateList = function () {
            $location.path('/optimove/templates');
        };

        $scope.editTemplate = function () {
            $location.path('/optimove/templates/' + $scope.template.id);
        };

        $scope.addLanguage = function (currentLang) {
            $scope.editLanguage($scope.template, currentLang, OptimoveService);
        };

        $scope.deleteLanguage = function (lang) {
            Restangular.all('api/template/' + lang.templateId + '/language/' + lang.lang).remove().then(function (result) {
                $scope.loadTemplateLanguages();
            }, function (response) {
                alert('Unable to delete Template Language: ' + response.data.message);
            });
        }

        /** Template Languages **/
        $scope.baseLanguages = Restangular.all('api/template/' + $scope.template.id + '/language');

        $scope.loadTemplateLanguages = function () {
            $scope.baseLanguages.getList().then(function (lines) {
                $scope.templateLanguages = lines;
            });
        };

        $scope.loadTemplateLanguages();
        /** Template Languages **/

        $scope.editLanguage = function (template, currentLang, OptimoveService) {
            // Show modal
            var parentElem = angular.element(document.getElementById('languageList'));
            var $ctrl = this;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'editLanguage.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                controller: ['$scope', '$modalInstance', 'Restangular', function ($scope, $modalInstance, Restangular) {
                    $scope.template = {
                        templateId: template.id
                    };
                    $scope.languages = [];
                    $scope.isUnicode = false;
                    $scope.messageCount = 1;
                    $scope.blenderTokens = [];
                    $scope.shorturl = template.shorturl;
                    $scope.tokenId = template.tokenId;
                    $scope.templateMacrosReplacements = {};
                    $scope.templateMacroReplacementRE = null;
                    $scope.dummyText = '';

                    $scope.shortUrlMessageLength = 0;
                    $scope.unshortUrlMessageLength = 0;
                    $scope.shortUrlExample = '';
                    $scope.urlExp = "\\b((?:[a-z][\\w-]+:(?:\\/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\\/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’]))"
                    $scope.urlRegex = new RegExp($scope.urlExp, 'g');

                    $scope.saving = false;

                    // Get ShortURL Domain
                    OptimoveService.getShortUrlDomain(
                        function(shortUrl) {
                            $scope.shortUrlDomain = shortUrl;
                            $scope.watchTemplateText();
                        },
                        $scope.tokenId
                    );

                    Restangular.all("api/languages").getList().then(function (languages) {
                        $scope.languages = languages;
                    });

                    Restangular.all("api/optimove/attributes").getList().then(function (attributes) {
                        if (attributes.length == 0)
                            return;

                        $scope.templateMacros = attributes;

                        // Create replacement array to help calculate message length
                        for(var i=0;i<attributes.length;i++) {
                            var searchKey = '<' + attributes[i].name + '>'
                            var replaceValue = loremIpsum.substring(0, attributes[i].length);
                            if(attributes[i].description.includes('URL')) {
                                var additionalChars = (attributes[i].length - dummyUrl.length + 1);
                                if(additionalChars < 0)
                                    replaceValue = dummyUrl;
                                else
                                    replaceValue = dummyUrl + loremIpsum.substring(0, additionalChars).replaceAll(' ', '_');
                            }

                            $scope.templateMacrosReplacements[searchKey] = replaceValue;
                        }
                        $scope.templateMacroReplacementRE = new RegExp(Object.keys($scope.templateMacrosReplacements).join("|"), "gi");
                        $scope.watchTemplateText();
                    });

                    $scope.$watch('selectedMacro', function () {
                        if ($scope.selectedMacro === undefined)
                            return;
                        $rootScope.$broadcast('add', $scope.selectedMacro);
                        $scope.selectedMacro = undefined;
                    });

                    $scope.$watch('template.text', function() {
                        $scope.watchTemplateText();
                    });

                    $scope.watchTemplateText = function () {
                        if ($scope.template.text == null)
                            return;

                        // Now do the same with replacement texts
                        $scope.dummyText = $scope.template.text.replace($scope.templateMacroReplacementRE, function(matched) {
                            return $scope.templateMacrosReplacements[matched];
                        });

                        console.log($scope.template);

                        $scope.isUnicode = Gsm0338Service.isUnicode($scope.template.text);
                        $scope.unshortUrlMessageLength = $scope.dummyText.length

                        var found = $scope.urlRegex.exec($scope.dummyText);
                        if (found != null) {
                            var replaced = $scope.dummyText.replace($scope.urlRegex, function (str) {
                                const newUrl = $scope.shortUrlDomain + shortUrlPathSample;
                                if(willUrlBeShortened(str, newUrl))
                                    return newUrl;
                                else
                                    return str;
                            });

                            $scope.shortUrlExample = 'E.g. ' + found[0] + ' -> ' + $scope.shortUrlDomain + shortUrlPathSample;
                            $scope.shortUrlMessageLength = replaced.length;

                            if($scope.shorturl)
                                $scope.dummyText = replaced;

                        } else {
                            $scope.shortUrlExample = 'URL not found in message';
                            $scope.shortUrlMessageLength = $scope.dummyText.length;
                        }

                        $scope.messageCount = Gsm0338Service.getSegmentCount($scope.dummyText);

                    };

                    $scope.saveLanguage = function () {
                        $scope.saving = true;
                        Restangular.all('api/template/' + $scope.template.templateId + '/language/' + $scope.template.lang).post($scope.template).then(function (result) {
                            $scope.saving = false;
                            // $scope.status = 'Mapping saved successfully';
                            $modalInstance.dismiss("Saved");
                        }, function (response) {
                            alert('Unable to save Template Language: ' + response.data.message);
                            $scope.status = response.data.message;
                            $scope.saving = false;
                        });

                    }

                    $scope.cancel = function () {
                        $modalInstance.dismiss("Reason");
                    }

                    $scope.toggleTemplatePreview = function() {
                        var x = document.getElementById("template_preview");
                        if (x.style.display === "none" || x.style.display === "") {
                            x.style.display = "block";
                        } else {
                            x.style.display = "none";
                        }
                    };

                    if (currentLang != null) {
                        $scope.template = currentLang;
                    }
                    // console.log("Current lang:", currentLang);

                }]
            });

            modalInstance.result.then(function (result) {
                // console.log("Template Language modal result: ", result);
                $scope.loadTemplateLanguages();

            }, function (reason) {
                $scope.loadTemplateLanguages();
            });

        };
    }];

    return templateControllers;

});




