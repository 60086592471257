/**
 * Created by lettuce on 6/07/15.
 */
/*global define */

'use strict';

define('contacts',[],function () {
    console.log('contact controllers 1');
    /* Controllers */

    var contactControllers = {};

    contactControllers.UploadCtrl = ['$scope', 'Upload', '$location', function ($scope, Upload, $location) {
        $scope.status = '';
        $scope.hideProgress = true;
        $scope.progressValue = 0;
        $scope.countTo = 100;
        $scope.countFrom = 0;
        $scope.completedFiles = [];
        $scope.contactLists = [];


        $scope.$watch('files', function () {
            $scope.upload($scope.files);
        });

        $scope.upload = function (files) {
            if (files && files.length) {
                $scope.hideProgress = false;
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    Upload.upload({
                        url: 'api/file/upload',
                        file: file
                    }).progress(function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.progressValue = progressPercentage;
                        console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
                    }).success(function (data, status, headers, config) {
                        console.log('file ' + config.file.name + 'uploaded. Response: ', data);
                        $scope.completedFiles.push(data);
                        $scope.hideProgress = true;
                        $location.path('/optimove/contacts/map/' + data.id);
                    });
                }
            }
        };
    }];

    contactControllers.MappingCtrl = ['$scope', '$routeParams', 'Restangular', '$location', function ($scope, $routeParams, Restangular, $location) {

        /** Upload to selected list **/
        $scope.contactLists = [];
        $scope.selectedList = {};

        // Get user property WEB_UPLOAD_CONTACT_LIVE_DISABLED for logged in user
        Restangular.one("api/property/WEB_UPLOAD_CONTACT_LIVE_DISABLED").get().then(function (propValue) {

            if (propValue.message == null) {
                $scope.webUploadContactLiveDisabled = false;
            } else {
                $scope.webUploadContactLiveDisabled = propValue.message == 'true' ? true : false;
            }
        });

        var baseLists = Restangular.all('api/contactlists');

        baseLists.getList().then(function (lines) {
            $scope.contactLists = lines;
            angular.forEach($scope.contactLists, function (value) {
                if (value.live)
                    $scope.selectList(value);
            });
        });

        $scope.selectList = function (list) {
            $scope.selectedList = list;
            $scope.mapping.listId = list.id;
        };

        /** mapping **/

        $scope.mappingOptions = [
            {name: "", value: ""},
            {name: "Mobile", value: "Mobile"},
            {name: "Customer ID", value: "CustomerId"},
            {name: "First Name", value: "FirstName"},
            {name: "Last Name", value: "LastName"},
            {name: "Email", value: "Email"},
            {name: "Custom 1", value: "Custom1"},
            {name: "Custom 2", value: "Custom2"},
            {name: "Custom 3", value: "Custom3"},
            {name: "Custom 4", value: "Custom4"},
            {name: "Custom 5", value: "Custom5"},
            {name: "Custom 6", value: "Custom6"},
            {name: "Custom 7", value: "Custom7"},
            {name: "Custom 8", value: "Custom8"},
            {name: "Custom 9", value: "Custom9"},
            {name: "Custom 10", value: "Custom10"},
            {name: "Custom 11", value: "Custom11"},
            {name: "Custom 12", value: "Custom12"},
            {name: "Custom 13", value: "Custom13"},
            {name: "Custom 14", value: "Custom14"},
            {name: "Custom 15", value: "Custom15"},
            {name: "Custom 16", value: "Custom16"},
            {name: "Custom 17", value: "Custom17"},
            {name: "Custom 18", value: "Custom18"},
            {name: "Custom 19", value: "Custom19"},
            {name: "Custom 20", value: "Custom20"},
            {name: "Custom 21", value: "Custom21"},
            {name: "Custom 22", value: "Custom22"},
            {name: "Custom 23", value: "Custom23"},
            {name: "Custom 24", value: "Custom24"},
            {name: "Custom 25", value: "Custom25"},
            {name: "Custom 26", value: "Custom26"},
            {name: "Custom 27", value: "Custom27"},
            {name: "Custom 28", value: "Custom28"},
            {name: "Custom 29", value: "Custom29"},
            {name: "Custom 30", value: "Custom30"}
        ];

        $scope.dataLoading = false;
        $scope.mapping = {
            fileId: $routeParams.fileId,
            startRow: 1,
            columns: [],
            listId: -1
        };

        Restangular.all("api/file/" + $routeParams.fileId + "/preview").getList().then(function (lines) {
            $scope.lines = lines;

        });

        $scope.cancelImport = function () {
            $location.path('/optimove/contacts');
        };

        $scope.finishImport = function () {
            if ($scope.mapping.columns.indexOf('Mobile') == -1) {
                $scope.status = 'You must choose a Mobile column';
                return;
            }
            if ($scope.mapping.columns.indexOf('CustomerId') == -1) {
                $scope.status = 'You must choose a Customer ID column';
                return;
            }
            $scope.status = '';
            $scope.dataLoading = true;

            console.log($scope.mapping);

            Restangular.all('api/optimove/contacts/finish').post($scope.mapping).then(function (response) {
                console.log('result', response);
                $location.path('/optimove/contacts');
                $scope.dataLoading = false;
            }, function (response) {
                $scope.status = response.data.message;
                $scope.dataLoading = false;
            });
        }

    }];

    contactControllers.createListCtrl = contactControllers.ListCtrl = ['$scope', 'Restangular', '$location', function ($scope, Restangular, $location) {

    }];

    contactControllers.ListCtrl = ['$scope', 'Restangular', '$location', '$modal', function ($scope, Restangular, $location, $modal) {
        $scope.contactLists = [];
        $scope.loading = false;
        $scope.selectedList = {name: "Production List", id: -1, live: true};

        // Get user property WEB_UPLOAD_CONTACT_LIVE_DISABLED for logged in user
        Restangular.one("api/property/WEB_UPLOAD_CONTACT_LIVE_DISABLED").get().then(function (propValue) {

            if (propValue.message == null) {
                $scope.webUploadContactLiveDisabled = false;
            } else {
                $scope.webUploadContactLiveDisabled = propValue.message == 'true' ? true : false;
            }
        });

        $scope.searchParams = {
            offset: 0,
            limit: 10,
            search: '',
            list: -1
        };

        $scope.setPage = function (page) {
            this.searchParams.offset = this.searchParams.limit * page;
            this.refresh();
        };

        $scope.nextPage = function () {
            this.searchParams.offset = this.searchParams.offset + this.searchParams.limit;
            this.refresh();
        };

        $scope.prevPage = function () {
            this.searchParams.offset = this.searchParams.offset - this.searchParams.limit;
            this.refresh();
        };

        $scope.uploadContacts = function () {
            $location.path('/optimove/contacts/upload');
        };

        $scope.search = function () {
            this.searchParams.offset = 0;
            this.refresh();
        };

        $scope.clearSearch = function () {
            this.searchParams.offset = 0;
            this.searchParams.search = '';
            this.refresh();
        };

        $scope.refresh = function (offset) {
            if (!angular.isUndefined(offset))
                $scope.searchParams.offset = offset;

            $scope.loading = true;
            var endpoint = Restangular.all("api/optimove/contacts");

            endpoint.getList($scope.searchParams).then(function (response) {
                $scope.contacts = response;
                $scope.loading = false;
            });
        };

        $scope.selectList = function (list) {
            $scope.selectedList = list;
            $scope.searchParams.list = list.id;
            $scope.refresh(0);
        };


        $scope.deleteList = function (event, list) {
            if (confirm('Are you sure you want to delete list' + list.name + '?\nAll contacts will be deleted!')) {
                // Delete it!
                list.remove().then(function (lines) {
                    baseLists.getList().then(function (lines) {
                        $scope.contactLists = lines;
                        angular.forEach($scope.contactLists, function (value) {
                            if (value.live)
                                $scope.selectList(value);
                        });
                    });
                    event.stopPropagation();
                });
            } else {
                // Do nothing!
            }



            return false;
        };

        var baseLists = Restangular.all('api/contactlists');

        baseLists.getList().then(function (lines) {
            $scope.contactLists = lines;
        });

        $scope.createList = function () {
            // Show modal
            var parentElem = angular.element(document.getElementById('contactList'));
            var $ctrl = this;

            var modalInstance = $modal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'createContactList.html',
                controllerAs: '$ctrl',
                // backdrop: 'static',
                appendTo: parentElem,
                resolve: {
                    formData: function () {
                        return {
                            //fields: getFormFields(),
                            //model: {}
                        }
                    }
                },
                controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
                    $scope.listName = "New List Name";

                    $scope.saveList = function () {
                        $modalInstance.close($scope.listName);
                    }
                    $scope.cancel = function () {
                        $modalInstance.dismiss("Reason");
                    }
                }]
            });

            modalInstance.result.then(function (listName) {
                var newList = {name: listName};
                baseLists.post(newList).then(function (result) {
                    baseLists.getList().then(function (lines) {
                        $scope.contactLists = lines;
                        $scope.selectList(result);
                    });
                }, function (response) {
                    $scope.status = response.data.message;
                    $scope.saving = false;
                });
            }, function (reason) {
                //console.log('Modal dismissed: ' + reason);
            });

        };

        $scope.refresh();


    }];

    return contactControllers;

});


