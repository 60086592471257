/*global define */

'use strict';

define('sendmessage',[],function () {

    /* Controllers */

    var sendControllers = {};

    sendControllers.SendMessageCtrl = ['$scope', '$location', 'Restangular', 'Gsm0338Service', 'OptimoveService', function ($scope, $location, Restangular, Gsm0338Service, OptimoveService) {

        $scope.isUnicode = false;
        $scope.messageCount = 1;
        $scope.shortUrlMessageLength = 0;
        $scope.shortUrlExample = '';
        $scope.urlExp = "\\b((?:[a-z][\\w-]+:(?:\\/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\\/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:'\\\".,<>?«»“”‘’]))"
        $scope.urlRegex = new RegExp($scope.urlExp, 'g');

        $scope.customerCount = 0;
        $scope.customers = '';
        $scope.message = {};
        $scope.saving = false;
        $scope.newTemplate = {"id":-1, "name":"New Message","text":"","originator":"","shorturl":true,"routeId":"mglobal"};
        $scope.templates = [$scope.newTemplate];

        Restangular.all("api/optimove/templates?limit=200").getList().then(function (tpls) {
            $scope.templates = tpls;
            $scope.templates.unshift($scope.newTemplate);
            $scope.message.templateId = tpls[0];
        });

        OptimoveService.getShortUrlDomain(function(shortUrl) { $scope.shortUrlDomain = shortUrl; });

        $scope.blenderTokens = [];
      Restangular.all("api/blender/tokens").getList().then(function (tokens) {
        $scope.blenderTokens = tokens;
        $scope.newTemplate.tokenId = tokens[0].id;
      });

      $scope.$watch('message.template.text', function () {
            if ($scope.message.template === undefined)
                return;

            $scope.messageCount = Gsm0338Service.getSegmentCount($scope.message.template.text);
            $scope.isUnicode = Gsm0338Service.isUnicode($scope.message.template.text);

            if ($scope.message.template.text == null)
                return;

            var found = $scope.urlRegex.exec($scope.message.template.text);
            if (found != null) {
                var replaced = $scope.message.template.text.replace($scope.urlRegex, function (str) {
                    return $scope.shortUrlDomain + "/abcdefg";
                    //return str.toUpperCase();
                });

                $scope.shortUrlExample = 'E.g. ' + found[0] + ' -> ' + $scope.shortUrlDomain + '/abcdefg';
                $scope.shortUrlMessageLength = replaced.length;
            } else {
                $scope.shortUrlExample = 'URL not found in message';
                $scope.shortUrlMessageLength = $scope.message.template.text.length;
            }
        });

        $scope.$watch('customers', function() {

            if ($scope.customers === undefined) {
                $scope.message.customers = [];
                $scope.customerCount = 0;
                return;
            }

            $scope.message.customers = $scope.customers.split('\n');
            $scope.customerCount = $scope.message.customers.length;
        });


        $scope.sendMessage = function () {
            $scope.saving = true;

            var sendData = {
                templateId: $scope.message.template.id,
                customers: $scope.message.customers
            }

            if(sendData.templateId < 0) {
              console.log($scope.message.template.token);
              sendData.tokenId = $scope.message.template.tokenId;
              sendData.originator = $scope.message.template.originator;
              sendData.text = $scope.message.template.text;
            }
            Restangular.all('api/optimove/contacts/send').post(sendData).then(function (response) {
                $scope.saving = false;
                $scope.status = response.message;
                console.log('response', response);
            }, function (response) {
                $scope.status = response.data.message;
                $scope.saving = false;
            });
        };

    }];


    return sendControllers;

});




